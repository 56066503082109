import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useGetUserMatchSession from "../../hooks/Games/Inplay/useGetUserMatchSession";
import useCreateLedger from "../../hooks/Games/Inplay/useCreateLedger";
import useCompleteGame from "../../hooks/Games/Inplay/useCompleteGame";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min";

const EndMatchModal = ({ modalTeams, matchId }) => {
  const navigate = useNavigate();

  const [gameWinner, setGameWinner] = useState("");

  const { mutate: getUserMatchSession } = useGetUserMatchSession();
  const { mutate: createLedger } = useCreateLedger();
  const { mutate: completeGame } = useCompleteGame();

  const [saveLoader, setSaveLoader] = useState(false);

  const handleEndGame = () => {
    if (gameWinner === "") {
      alert("Please select the game winner");
      return;
    }

    const matchSessionPayload = {
      acc_type: "admin",
      user_id: "user_d9ada001c561",
      match_id: matchId,
    };

    getUserMatchSession(matchSessionPayload, {
      onSuccess: (data) => {
        console.log("getUserMatchSession", data);
        const result = data.data.SessionData;

        result.forEach((el) => {
          let m_amt = 0;

          if (gameWinner === "A") {
            m_amt = el.bettingData[0].total_teamA;
          } else if (gameWinner === "B") {
            m_amt = el.bettingData[0].total_TeamB;
          } else {
            m_amt = 0;
          }

          let s_amt_tot =
            el.SessoinData.length !== 0
              ? el.SessoinData.map((o) => o.amount).reduce((a, c) => {
                  return a + c;
                })
              : 0;

          let s_amt = 0;

          if (el.SessoinData.length !== 0) {
            el.SessoinData.forEach((ele) => {
              if (ele.declare != null) {
                if (ele.rate < 1) {
                  if (ele.won) {
                    s_amt = s_amt + ele.amount * ele.rate;
                  } else {
                    s_amt = s_amt - ele.amount;
                  }
                } else {
                  if (ele.won) {
                    s_amt = s_amt + ele.amount;
                  } else {
                    s_amt = s_amt - ele.amount * ele.rate;
                  }
                }
              }
            });
          }

          if (
            (m_amt === null || m_amt === 0) &&
            s_amt === 0 &&
            s_amt_tot === 0
          ) {
            console.log("No session or match amount");
            return;
          }

          s_amt = s_amt * -1;
          // let c_com = 0;
          let agent_m_com = 0;
          let super_agent_m_com = 0;
          // let m_comm = 0;
          let client_m_com = 0;
          let master_m_com = 0;
          let sub_admin_m_com = 0;
          let super_admin_m_com = 0;
          let mob_charge = 0;

          if (m_amt !== 0) {
            m_amt = m_amt * -1;
          }
          if (m_amt > 0) {
            // c_com =
            //   (m_amt * el.match_commission) / 100 +
            //   (s_amt_tot * el.session_commission) / 100;
            agent_m_com = (m_amt * el.parents.match_commission) / 100;
            // m_comm = (m_amt * el.match_commission) / 100;
            client_m_com = (m_amt * el.match_commission) / 100;
            super_agent_m_com =
              (m_amt * el.parents.parents.match_commission) / 100;
            master_m_com =
              (m_amt * el.parents.parents.parents.match_commission) / 100;
            sub_admin_m_com =
              (m_amt * el.parents.parents.parents.parents.match_commission) /
              100;
            super_admin_m_com =
              (m_amt *
                el.parents.parents.parents.parents.parents.match_commission) /
              100;
          }

          let client_t_amt =
            client_m_com + (s_amt_tot * el.session_commission) / 100;

          let client_net_amt = m_amt + s_amt - client_t_amt;

          let client_final =
            m_amt + s_amt - client_t_amt + (mob_charge * el.mobile_share) / 100;

          let agent_t_com =
            agent_m_com + (s_amt_tot * el.parents.session_commission) / 100;
          let agent_net_amt = 0;
          agent_net_amt = m_amt + s_amt - agent_t_com;
          let agent_shr_amount =
            el.parents.share !== 0
              ? (el.parents.share / 100) * agent_net_amt
              : 0;
          let agent_final = agent_net_amt - agent_shr_amount;

          let super_agent_t_com =
            super_agent_m_com +
            (s_amt_tot * el.parents.parents.session_commission) / 100;
          let super_agent_net_amt = 0;
          super_agent_net_amt = m_amt + s_amt - super_agent_t_com;
          let super_agent_shr_amount =
            (super_agent_net_amt * el.parents.parents.share) / 100;
          let super_agent_final = super_agent_net_amt - super_agent_shr_amount;

          let master_t_com =
            master_m_com +
            (s_amt_tot * el.parents.parents.parents.session_commission) / 100;
          let master_net_amt = 0;
          master_net_amt = m_amt + s_amt - master_t_com;
          let master_shr_amount =
            (master_net_amt * el.parents.parents.parents.share) / 100;
          let master_final = master_net_amt - master_shr_amount;

          let sub_admin_t_com =
            sub_admin_m_com +
            (s_amt_tot *
              el.parents.parents.parents.parents.session_commission) /
              100;
          let sub_admin_net_amt = 0;
          sub_admin_net_amt = m_amt + s_amt - sub_admin_t_com;
          let sub_admin_shr_amount =
            (sub_admin_net_amt * el.parents.parents.parents.parents.share) /
            100;
          let sub_admin_final = sub_admin_net_amt - sub_admin_shr_amount;

          let super_admin_t_com =
            super_admin_m_com +
            (s_amt_tot *
              el.parents.parents.parents.parents.parents.session_commission) /
              100;
          let super_admin_net_amt = 0;
          super_admin_net_amt = m_amt + s_amt - super_admin_t_com;
          let super_admin_shr_amount =
            (super_admin_net_amt *
              el.parents.parents.parents.parents.parents.share) /
            100;
          let super_admin_final = super_admin_net_amt - super_admin_shr_amount;

          let finalResult = [];

          finalResult.push({
            user_id: el.id,
            agent_id: el.parents.id,
            super_agent_id: el.parents.parents.id,
            master_id: el.parents.parents.parents.id,
            sub_admin_id: el.parents.parents.parents.parents.id,
            super_admin_id: el.parents.parents.parents.parents.parents.id,
            m_amt: m_amt,
            s_amt: s_amt,
            // m_com: m_comm,
            // s_com: s_amt_tot * el.session_commission/100,
            c_com: client_t_amt,
            net_amt: client_net_amt,
            c_mob: (mob_charge * el.mobile_share) / 100,
            final: client_final,
            agent_m_com: agent_m_com,
            agent_s_com: (s_amt * el.parents.session_commission) / 100,
            agent_t_com: agent_t_com,
            agent_net_amt: agent_net_amt,
            agent_shr_amt: agent_shr_amount,
            agent_mob_app: mob_charge,
            agent_final: agent_final,
            super_agent_m_com: super_agent_m_com,
            super_agent_s_com:
              (s_amt * el.parents.parents.session_commission) / 100,
            super_agent_t_com: super_agent_t_com,
            super_agent_shr_amt: super_agent_shr_amount,
            super_agent_net_amt: super_agent_net_amt,
            super_agent_mob_app: mob_charge,
            super_agent_final: super_agent_final,
            master_m_com: master_m_com,
            master_s_com:
              (s_amt * el.parents.parents.parents.session_commission) / 100,
            master_t_com: master_t_com,
            master_shr_amt: master_shr_amount,
            master_net_amt: master_net_amt,
            master_mob_app: mob_charge,
            master_final: master_final,
            sub_admin_m_com: master_m_com,
            sub_admin_s_com:
              (s_amt * el.parents.parents.parents.parents.session_commission) /
              100,
            sub_admin_t_com: sub_admin_t_com,
            sub_admin_shr_amt: sub_admin_shr_amount,
            sub_admin_net_amt: sub_admin_net_amt,
            sub_admin_mob_app: mob_charge,
            sub_admin_final: sub_admin_final,
            super_admin_m_com: master_m_com,
            super_admin_s_com:
              (s_amt *
                el.parents.parents.parents.parents.parents.session_commission) /
              100,
            super_admin_t_com: super_admin_t_com,
            super_admin_shr_amt: super_admin_shr_amount,
            super_admin_net_amt: super_admin_net_amt,
            super_admin_mob_app: mob_charge,
            super_admin_final: super_admin_final,
            match_decision: 1,
            match_id: matchId,
          });

          const createLedgerPayload = finalResult[0];

          createLedger(createLedgerPayload, {
            onSuccess: () => {
              console.log("Ledger Updated successfully");
            },
            onError: (error) => {
              console.log("createLedgerError", error);
            },
          });

          finalResult = [];
        });

        const completeGamePayload = {
          match_id: matchId,
          won_by: gameWinner,
        };

        completeGame(completeGamePayload, {
          onSuccess: () => {
            console.log("Match is successfully ended!");
            setSaveLoader(false);
            const modal = Modal.getInstance(
              document.getElementById("endMatchModal")
            );
            modal.hide();
            navigate("/inplay");
            window.location.reload();
          },
          onError: (error) => {
            console.log("completeGameError", error);
          },
        });
      },
      onError: (error) => {
        console.log("getUserMatchSessionError", error);
      },
    });
  };

  return (
    <div
      className="modal fade"
      id="endMatchModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="endMatchModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="exampleModalLabel">
              End Game
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              {modalTeams?.length !== 0 ? (
                <div className="form-group">
                  <label className="form-label" htmlFor="subAdminParent">
                    Game won by:
                  </label>
                  <select
                    className="form-control"
                    id="gameWinner"
                    name="gameWinner"
                    onChange={(e) => {
                      console.log(e.target.value);
                      setGameWinner(e.target.value);
                    }}
                    defaultValue={""}
                  >
                    <option value="">Select...</option>
                    {modalTeams?.map((modalTeam, index) => (
                      <option value={modalTeam.id} key={index}>
                        {modalTeam.team}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={(e) => {
                e.preventDefault();
                if (!saveLoader) {
                  setSaveLoader(true);
                  handleEndGame();
                }
              }}
              disabled={saveLoader}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EndMatchModal;
