import React, { useEffect, useState } from "react";
import "./ClientMasterLimitUpdate.css";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import useGetClientMasterList from "../../hooks/ClientMaster/useGetClientMasterList";
import useGetParentLimit from "../../hooks/useGetParentLimit";
import useUpdateLimitClient from "../../hooks/useUpdateLimitClient";
import { useQueryClient } from "react-query";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function ClientMasterLimitUpdate() {
  const queryClient = useQueryClient();
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: accountStatus } = useGetAccountStatus();

  const { data: ClientMasterListData } = useGetClientMasterList();
  const { mutate: getParentLimit } = useGetParentLimit();
  const { mutate: updateLimitClient } = useUpdateLimitClient();
  const [data, setData] = useState([]);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const listData = ClientMasterListData?.data;

    if (listData) {
      listData.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      setData(listData);
    }
  }, [ClientMasterListData]);

  const handleAddLimit = (id, clientLimit) => {
    const payload = {
      acc_type: "user",
      user_id: id,
    };

    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    getParentLimit(payload, {
      onSuccess: (data) => {
        const parentLimit = Math.round(data.data.data);

        if (value > parentLimit) {
          toast.error(
            "You can not add balance greater than your Parent limit balance"
          );
          inputElement.value = "";
          return;
        }

        const clientLimitPayload = {
          acc_type: "user",
          user_id: id,
          limit: Math.floor(Number(clientLimit)) + Math.floor(Number(value)),
        };

        updateLimitClient(clientLimitPayload, {
          onSuccess: () => {
            queryClient.invalidateQueries("get-client-master-list");
            toast.success("Limit Added Successfully");

            inputElement.value = "";
          },
          onError: (error) => {
            console.error(error);
          },
        });
      },

      onError: (error) => {
        toast.error(error);
      },
    });
  };

  const handleMinusLimit = (id, clientLimit) => {
    const inputElement = document.getElementById(id);
    const value = inputElement.value;

    if (!value || value < 0 || value === "0") {
      toast.error("Please enter valid Limit");
      return;
    }

    if (value > clientLimit || value === clientLimit) {
      toast.error("You can not deduct balance greater than your limit balance");
      inputElement.value = "";
      return;
    }

    const clientLimitPayload = {
      acc_type: "user",
      user_id: id,
      limit: Math.floor(Number(clientLimit)) - Math.floor(Number(value)),
    };

    updateLimitClient(clientLimitPayload, {
      onSuccess: () => {
        queryClient.invalidateQueries("get-client-master-list");
        toast.success("Limit Added Successfully");

        inputElement.value = "";
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Client</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/client">Client</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Limit Update</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content" style={{ overflowX: "auto" }}>
        <div className="container-fluid">
          <div className="col-6">
            <div className="card" style={{ width: "685px" }}>
              <div className="card-header bg-primary">
                <h5 className="text-white mb-0">Client Coin Details</h5>
              </div>

              <div className="card-body">
                <div className="">
                  <table
                    className="table table-bordered table-striped table-responsive w-100"
                    role="grid"
                  >
                    <thead>
                      <tr>
                        <th>S No</th>
                        <th>Client Name</th>
                        <th>Client Limit</th>
                        <th>Enter Limit</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((item, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>
                            {item.name} ({item.code})
                          </td>
                          <td>{Math.round(item.limit)}</td>

                          <td>
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Enter Limit"
                              min="0"
                              id={item.id}
                              style={{ width: "200px" }}
                            />
                          </td>
                          <td
                            className="action-btn-container"
                            id="agent-plus-minus-box1"
                          >
                            <Link
                              className="btn btn-primary mr-2 action-btns action-button-plus-minus"
                              onClick={(e) => {
                                e.preventDefault();
                                handleAddLimit(item.id, item.limit);
                              }}
                            >
                              Add
                            </Link>
                            <Link
                              className="btn btn-danger action-btns action-button-plus-minus"
                              onClick={(e) => {
                                e.preventDefault();
                                handleMinusLimit(item.id, item.limit);
                              }}
                            >
                              Minus
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
