/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import "./InplayControlGame.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import useSocketBetguru from "../../hooks/Sockets/useSocketBetguru";
import useGetMatchDecisionSession from "../../hooks/Games/Inplay/useGetMatchDecisionSession";
import useUpdateDecision from "../../hooks/Games/Inplay/useUpdateDecision";
import useRevertDecision from "../../hooks/Games/Inplay/useRevertDecision";
import UpdateDecisionModal from "../../Components/Inplay/updateDecisionModal";
import Swal from "sweetalert2";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function InplayDecisionGame() {
  // const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: accountStatus } = useGetAccountStatus();
  const navigate = useNavigate();
  const location = useLocation();
  let gameData = [];
  let gameCode = "";
  let scoreCode = "";
  if (location.state) {
    gameData = location.state;
    gameCode = location.state.code;
    scoreCode = location.state.score_code;
  }
  let accountType = localStorage.getItem("account");

  const { mutate: getMatchDecisionSession } = useGetMatchDecisionSession();
  const { mutate: updateDecision } = useUpdateDecision();
  const { mutate: revertDecision } = useRevertDecision();

  const [sessionData, setSessionData] = useState([]);
  const [updateResult, setUpdateResult] = useState(0);
  const [updateSession, setUpdateSession] = useState("");

  const [scoreBoardType, setScoreBoardType] = useState("");
  const [saveLoader, setSaveLoader] = useState(false);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMatchDecisionSession = (payload) => {
    getMatchDecisionSession(payload, {
      onSuccess: (data) => {
        if (data) {
          var cityMap = new Map();

          data.data.data.forEach((p) => {
            if (cityMap.has(p.session)) {
              let existing = cityMap.get(p.session);

              if (existing.declare === null && p.declare !== null) {
                cityMap.set(p.session, p);
              }
            } else {
              cityMap.set(p.session, p);
            }
          });

          var allsessionForDecision = [...cityMap.values()];

          allsessionForDecision = allsessionForDecision.sort(function (a, b) {
            if (a.declare === null) return 1;
            if (b.declare === null) return -1;
            return a.declare - b.declare;
          });

          setSessionData(allsessionForDecision);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  function handleUpdateResult() {
    const gameId = gameData.id;
    const payload = {
      match_id: gameId,
      session: updateSession,
      declare: Number(updateResult),
    };

    updateDecision(payload, {
      onSuccess: (data) => {
        console.log(data);
        toast.success("Decision is successfully updated");

        setSaveLoader(false);
        setUpdateResult(0);

        const gameId = gameData.id;
        const payload = {
          match_id: gameId,
        };

        handleMatchDecisionSession(payload);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  }

  function handleRevertDecision(session) {
    Swal.fire({
      title: "Are you sure?",
      text: "You want to redeclare the match session decision?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Revert it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const gameId = gameData.id;
        const payload = {
          match_id: gameId,
          session: session,
        };

        revertDecision(payload, {
          onSuccess: (data) => {
            console.log(data);
            toast.success("Decision is successfully undeclared!");

            setSaveLoader(false);

            const payload2 = {
              match_id: gameId,
            };

            handleMatchDecisionSession(payload2);
          },
          onError: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  useEffect(() => {
    if (accountType === "admin" || accountType === "power_user") {
      console.log("ok");
    } else {
      navigate("/inplay");
      toast.error("You don't have access!");
    }

    const gameId = gameData.id;
    const payload = {
      match_id: gameId,
    };

    if (scoreCode !== undefined || scoreCode !== "" || scoreCode !== null) {
      if (scoreCode.startsWith("1")) {
        setScoreBoardType("External");
      } else {
        setScoreBoardType("Betguru");
      }
    }

    handleMatchDecisionSession(payload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log(saveLoader);
  }, [saveLoader]);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Decision Game</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Decision Game</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row mb-4">
          <div className="col-md-6 w-100">
            <div className="accordion" id="accordionExample2">
              <div className="accordion-item">
                <h2 className="accordion-header">
                  <button
                    className="accordion-button"
                    id="create-button-1"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseTwo"
                    aria-expanded="true"
                    aria-controls="collapseTwo"
                  >
                    Sessions
                  </button>
                </h2>
                <div
                  id="collapseTwo"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingTwo"
                  data-bs-parent="#accordionExample2"
                >
                  <div className="accordion-body">
                    {scoreBoardType === "Betguru" ? (
                      <iframe
                        title="demo"
                        width="100%"
                        src={`https://score.zioplay.live/scorenew.html?MatchID=${scoreCode}`}
                        style={{ height: "160px", marginTop: "-16px" }}
                      ></iframe>
                    ) : (
                      <iframe
                        title="demo"
                        width="100%"
                        src={`https://winx777.com/score/?market_id=${scoreCode}`}
                        style={{
                          height: "180px",
                          marginTop: "-16px",
                          marginBottom: "0px",
                        }}
                      ></iframe>
                    )}

                    <div className="table-responsive mt-3">
                      <div className="col-md-12">
                        <table className="table table-bordered">
                          <thead>
                            <tr>
                              <th>Session</th>
                              <th>Result</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {sessionData?.length !== 0 ? (
                              sessionData?.map((session, index) => (
                                <tr key={index}>
                                  <td>{session.session}</td>
                                  <td>{session.declare}</td>
                                  <td>
                                    <button
                                      type="button"
                                      className={`btn btn-sm ${
                                        session.declare === undefined ||
                                        session.declare === null
                                          ? "btn-primary"
                                          : "btn-danger"
                                      }`}
                                      data-bs-toggle={`${
                                        session.declare === undefined ||
                                        session.declare === null
                                          ? "modal"
                                          : ""
                                      }`}
                                      data-bs-target={`${
                                        session.declare === undefined ||
                                        session.declare === null
                                          ? "#updateDecisionModal"
                                          : ""
                                      }`}
                                      onClick={(e) => {
                                        e.preventDefault();
                                        if (!saveLoader) {
                                          session.declare === undefined ||
                                          session.declare === null
                                            ? setUpdateSession(session.session)
                                            : handleRevertDecision(
                                                session.session
                                              );

                                          if (
                                            session.declare === undefined ||
                                            session.declare === null
                                          ) {
                                            setSaveLoader(false);
                                          } else {
                                            setSaveLoader(true);
                                          }
                                        }
                                      }}
                                      disabled={saveLoader}
                                    >
                                      {session.declare === undefined ||
                                      session.declare === null
                                        ? "Update Result"
                                        : "Revert Decision"}
                                    </button>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="3">No Data Found</td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <UpdateDecisionModal
        setDeclare={setUpdateResult}
        handleUpdateResult={handleUpdateResult}
        saveLoader={saveLoader}
        setSaveLoader={setSaveLoader}
      />
    </div>
  );
}
