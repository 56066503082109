import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import useGetAccountStatus from "../hooks/useGetAccountStatus";
import useGetClientMasterList from "../hooks/ClientMaster/useGetClientMasterList";
import useGetPlusMinusData from "../hooks/useGetPlusMinusData";
import PageHeader from "../Components/PageHeader";
import Select from "react-select";
import { dateFormatter } from "../utils/dateFormatter";

export default function ClientPlusMinus() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: accountStatus } = useGetAccountStatus();
  const { mutate: getPlusMinusData } = useGetPlusMinusData();
  const { data: ClientMasterListData } = useGetClientMasterList();

  const [data, setData] = useState([]);
  const [plusMinusData, setPlusMinusData] = useState([]);
  const [completeData, setCompleteData] = useState([]);

  const [totals, setTotals] = useState({
    m_amt: 0,
    s_amt: 0,
    net_amt: 0,
    matchCommissionAmt: 0,
    c_comDiff: 0,
    c_com: 0,
    net_amtMinusC_com: 0,
  });

  function fetchCashData(selected) {
    const payload = {
      user_id: selected,
      acc_type: "user",
      logged_acc_type: String(accountType),
    };

    getPlusMinusData(payload, {
      onSuccess: (response) => {
        let updatedCashData = response.data;

        updatedCashData = updatedCashData.filter((item) => {
          return item.game !== null;
        });

        let dataTotals = {
          m_amt: 0,
          s_amt: 0,
          net_amt: 0,
          matchCommissionAmt: 0,
          c_comDiff: 0,
          c_com: 0,
          net_amtMinusC_com: 0,
        };

        updatedCashData.forEach((item) => {
          const matchCommissionAmt =
            item.m_amt < 0
              ? 0
              : item.m_amt * (item.user_accounts[0].match_commission / 100);
          const c_comDiff =
            item.m_amt < 0 ? item.c_com : item.c_com - matchCommissionAmt;
          const netAmtMinusC_com = item.m_amt + item.s_amt - item.c_com;

          const netAmt = item.m_amt + item.s_amt;

          dataTotals.m_amt += item.m_amt;
          dataTotals.s_amt += item.s_amt;
          dataTotals.net_amt += netAmt;
          dataTotals.matchCommissionAmt += matchCommissionAmt;
          dataTotals.c_comDiff += c_comDiff;
          dataTotals.c_com += item.c_com;
          dataTotals.net_amtMinusC_com += netAmtMinusC_com;
        });

        setTotals(dataTotals);

        setPlusMinusData(updatedCashData);
        setCompleteData(updatedCashData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData(e.target);
    const user = formData.get("client");
    const from = formData.get("fromDate");
    const to = formData.get("toDate");

    if (user === "" && from === "" && to === "") {
      setPlusMinusData(completeData);
    } else {
      // eslint-disable-next-line array-callback-return
      const filteredData = completeData.filter((item) => {
        if (user !== "" && from !== "" && to !== "") {
          return (
            new Date(item.updatedAt) >= new Date(from) &&
            new Date(item.updatedAt) <= new Date(to)
          );
        } else if (user !== "" && from !== "") {
          return new Date(item.createdAt) >= new Date(from);
        } else if (user !== "" && to !== "") {
          return new Date(item.createdAt) <= new Date(to);
        }
      });

      let dataTotals = {
        m_amt: 0,
        s_amt: 0,
        net_amt: 0,
        matchCommissionAmt: 0,
        c_comDiff: 0,
        c_com: 0,
        net_amtMinusC_com: 0,
      };

      filteredData.forEach((item) => {
        const matchCommissionAmt =
          item.m_amt < 0
            ? 0
            : item.m_amt * (item.user_accounts[0].match_commission / 100);
        const c_comDiff =
          item.m_amt < 0 ? item.c_com : item.c_com - matchCommissionAmt;
        const netAmtMinusC_com = item.m_amt + item.s_amt - item.c_com;

        const netAmt = item.m_amt + item.s_amt;

        dataTotals.m_amt += item.m_amt;
        dataTotals.s_amt += item.s_amt;
        dataTotals.net_amt += netAmt;
        dataTotals.matchCommissionAmt += matchCommissionAmt;
        dataTotals.c_comDiff += c_comDiff;
        dataTotals.c_com += item.c_com;
        dataTotals.net_amtMinusC_com += netAmtMinusC_com;
      });

      setTotals(dataTotals);
      setPlusMinusData(filteredData);
    }
  }

  useEffect(() => {
    function transformData(data) {
      return data.map((item) => {
        return { value: item.id, label: `${item.code} ${item.name}` };
      });
    }

    if (ClientMasterListData !== undefined) {
      const listData = ClientMasterListData?.data;

      if (listData.length !== 0) {
        listData?.sort((a, b) => {
          return +new Date(b.createdAt) - +new Date(a.createdAt);
        });
        const transformedData = transformData(listData);
        setData(transformedData);
      }
    }
  }, [ClientMasterListData]);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper bg-white">
      <PageHeader title="Client Plus/Minus" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header bg-purple">
                <h3 className="">Client Report</h3>

                <form onSubmit={handleSubmit}>
                  <div id="row-1" className="row">
                    <div className="col-md-3 col-sm-12 col-12 mb-3 mt-1">
                      <div className="form-group">
                        <label>Client</label>
                        <Select
                          options={data.length !== 0 ? data : []}
                          className="basic-single"
                          classNamePrefix="select"
                          defaultValue={""}
                          isDisabled={false}
                          isLoading={data.length === 0 ? true : false}
                          isClearable={true}
                          isRtl={false}
                          isSearchable={true}
                          name="client"
                          onChange={(e) => {
                            setPlusMinusData([]);
                            setCompleteData([]);
                            const selected = e?.value;
                            if (!selected) {
                              return;
                            }
                            fetchCashData(selected);
                          }}
                          required
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-12 col-12 mb-3 mt-1">
                      <div className="form-group">
                        <label>Date From</label>
                        <input
                          type="date"
                          name="fromDate"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-12 col-12 mb-3 mt-1">
                      <div className="form-group">
                        <label>Date To</label>
                        <input
                          type="date"
                          name="toDate"
                          className="form-control"
                        />
                      </div>
                    </div>

                    <div className="col-md-3 col-sm-12 mb-3 mb-2 align-self-end">
                      <div className="form-group w-100 align-self-end">
                        <button
                          className="btn btn-primary btn-block"
                          type="submit"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="card-body">
              <table className="table table-bordered table-striped table-responsive">
                <thead>
                  <tr>
                    <th colspan="2"></th>
                    <th colspan="3">Match</th>
                    <th colspan="3">Commission</th>
                    <th colspan="2">Other Amount</th>
                    <th></th>
                  </tr>
                  <tr>
                    <th>Date</th>
                    <th style={{ width: "160px" }}>Match Name</th>
                    <th>Match</th>
                    <th>Session</th>
                    <th>Total</th>
                    <th>Match</th>
                    <th>Session</th>
                    <th>Total</th>
                    <th>Mob</th>
                    <th>Final</th>
                    <th>Balance</th>
                  </tr>
                  <tr>
                    <td
                      colspan={10}
                      className="text-right fw-bold"
                      height={"40px"}
                    >
                      Opening Balance
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  {plusMinusData.length !== 0 ? (
                    plusMinusData.map((item, index) => (
                      <tr key={index}>
                        <td>{dateFormatter(item.updatedAt)}</td>
                        <td>{item.game.name}</td>
                        <td>{item.m_amt.toFixed(2)}</td>
                        <td>{item.s_amt.toFixed(2)}</td>
                        <td>{(item.m_amt + item.s_amt).toFixed(2)}</td>
                        <td>
                          {item.m_amt < 0
                            ? 0
                            : (
                                item.m_amt *
                                (item.user_accounts[0].match_commission / 100)
                              ).toFixed(2)}
                        </td>
                        <td>
                          {item.m_amt < 0
                            ? item.c_com
                            : (
                                item.c_com -
                                item.m_amt *
                                  (item.user_accounts[0].match_commission / 100)
                              ).toFixed(2)}
                        </td>
                        <td>{item.c_com.toFixed(2)}</td>
                        <td>0.00</td>
                        <td>
                          {(item.m_amt + item.s_amt - item.c_com).toFixed(2)}
                        </td>
                        <td>
                          {(item.m_amt + item.s_amt - item.c_com).toFixed(2)}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        colSpan="11"
                        height={"60px"}
                        className="text-center text-xl fw-bold"
                        style={{ verticalAlign: "middle" }}
                      >
                        No Data Found
                      </td>
                    </tr>
                  )}
                  <tr
                    className="fw-bold text-md"
                    style={{
                      height: "44px",
                      verticalAlign: "middle",
                    }}
                  >
                    <td></td>
                    <td className="text-lg">TOTAL</td>
                    <td>{totals.m_amt.toFixed(2)}</td>
                    <td>{totals.s_amt.toFixed(2)}</td>
                    <td>{totals.net_amt.toFixed(2)}</td>
                    <td>{totals.matchCommissionAmt.toFixed(2)}</td>
                    <td>{totals.c_comDiff.toFixed(2)}</td>
                    <td>{totals.c_com.toFixed(2)}</td>
                    <td>0.00</td>
                    <td>{totals.net_amtMinusC_com.toFixed(2)}</td>
                    <td>{totals.net_amtMinusC_com.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
